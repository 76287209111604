/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
import React from 'react';
import EmailPreferencesComponent from '../../components/EmailPreferencesComponent';
import SinglePreferenceComponent from '../../components/EmailPreferencesComponent/singlePreferenceTemplate';

const EmailPreferencesTemplate = ({
  onClickOptions,
  onSave, emailPreferencesOptions,
  isValidEmailPreferenceOption, isgetAppLogoHeaderLoading,
  isSaveEmailPreferencesLoading,
  isOnlyOneEmailOptionAvailable, isReadOnly
}) => {

  return (
    <div>

      {isOnlyOneEmailOptionAvailable ? (
        <SinglePreferenceComponent
          onSave={onSave}
          isgetAppLogoHeaderLoading={isgetAppLogoHeaderLoading}
          isSaveEmailPreferencesLoading={isSaveEmailPreferencesLoading}
          verbiage={emailPreferencesOptions[0].verbiage.toLowerCase()}
          isReadOnly={isReadOnly}
        />
      )
        : (
          <EmailPreferencesComponent
            onClickOptions={onClickOptions}
            onSave={onSave}
            emailPreferencesOptions={emailPreferencesOptions}
            isgetAppLogoHeaderLoading={isgetAppLogoHeaderLoading}
            isSaveEmailPreferencesLoading={isSaveEmailPreferencesLoading}
            isValidEmailPreferenceOption={isValidEmailPreferenceOption}
            isReadOnly={isReadOnly}
          />
        )}
    </div>
  );

};

export default EmailPreferencesTemplate;
