import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '@uicl/ui-core/dist/LoadingIndicator';
import {
  PageDetailHeader, PageSectionHeader, EmailPreferencesParent,
  EmailPreferencesSubmitButton,
  EmailPreferencesContent,
  LoadingWrapper,
  EmailPreferencesSubmitButtonDiv,
  PageHeaderContent
} from './styles';

const SinglePreferenceComponent = ({
  onSave,
  isgetAppLogoHeaderLoading,
  isSaveEmailPreferencesLoading,
  verbiage,
  isReadOnly
}) => (
  <EmailPreferencesParent>
    {isgetAppLogoHeaderLoading ? (
      <center>
        <LoadingIndicator
          domID="basic-details-form-loading-indicator"
          length="300px"
        />
      </center>
    )
      : (
        <div>
          <EmailPreferencesContent>
            <PageHeaderContent>
              <PageDetailHeader
                dataTestId="email-prefheader-testid"
                domID="email-prefheader-domid"
                title="Email Preferences"
                headerTag="h1"
              />
              <PageSectionHeader
                dataTestId="test-sectionBasic"
                domID="test-id"
                description={`Click below to ${verbiage}`}
              />
            </PageHeaderContent>
            <EmailPreferencesSubmitButtonDiv>
              <EmailPreferencesSubmitButton
                buttonType="deEmphasized"
                dataTestId="test-defaultButton"
                domID="automation-id"
                name="UNSUBSCRIBE"
                onClick={() => onSave()}
                size="small"
                type="button"
                disabled={isReadOnly}
              />
              {isSaveEmailPreferencesLoading
                && (
                  <LoadingWrapper>
                    <LoadingIndicator
                      domID="basic-details-form-loading-indicator"
                      length="30px"
                    />
                  </LoadingWrapper>
                )}
            </EmailPreferencesSubmitButtonDiv>
          </EmailPreferencesContent>
        </div>
      )}
  </EmailPreferencesParent>
);
SinglePreferenceComponent.propTypes = {
  onSave: PropTypes.func,
  isgetAppLogoHeaderLoading: PropTypes.bool,
  isSaveEmailPreferencesLoading: PropTypes.bool,
};

export default SinglePreferenceComponent;
