import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const AppHeaderComponent = ({ idLogo }) => {

  const { idLogo: idClientLogo, logoName, logoImage } = idLogo;

  return (
    <div>
      {idClientLogo
        && (
          <header>
            <div>
              <img alt={logoName} src={`data:image/jpeg;base64,${logoImage}`} />
            </div>
          </header>
        )}
    </div>
  );
};

AppHeaderComponent.propTypes = {
  idLogo: PropTypes.object
};

export default AppHeaderComponent;
