import {
  GET_LOGO_APP_HEADER_REQUEST_TRIGGER,
  GET_LOGO_APP_HEADER_REQUEST_SUCCESS,
  GET_LOGO_APP_HEADER_REQUEST_ERROR
} from './constants';

// This is the action that the UI will dispatch to trigger our request loop
export const triggerGetAppLogoHeaderRequest = (payload = {}) => ({
  type: GET_LOGO_APP_HEADER_REQUEST_TRIGGER,
  payload,
});

export const getAppLogoHeaderRequestSuccess = (payload = {}) => ({
  type: GET_LOGO_APP_HEADER_REQUEST_SUCCESS,
  payload,
  error: null,
});

export const getAppLogoHeaderRequestError = (error = {}) => ({
  type: GET_LOGO_APP_HEADER_REQUEST_ERROR,
  error,
});
