import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { triggerSaveEmailPreferencesRequest } from '../../../redux/api/EmailPreferences/actions';
import EmailPreferencesTemplate from './emailPreferencesTemplate';
import NotificationBanner from '@uicl/ui-core/dist/NotificationBanner';
import { deleteNotification } from '../../../redux/app/notificationState/actions';
import { useSearchParams } from "react-router-dom";

const EmailPreferences = () => {
  const [emailPreferenceOption, setEmailPreferenceOption] = useState(true);
  const isValidEmailPreferenceOption = isString(emailPreferenceOption);
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notificationState);

  const errorGettingEmailPreferences = useSelector((state) => state.emailPreferencesState.errorGettingEmailPreferences);
  const errorGettingLogoAppHeader = useSelector((state) => state.getAppLogoHeaderState.errorGettingLogoAppHeader);
  const errorGettingUnsubscribeStatus = useSelector((state) => state.unsubscribeEmail.errorGettingOneClickUnsubscribe);

  const emailPreferencesOptions = useSelector((state) => state.emailPreferencesState.successGettingEmailPreferences);
  const vo = useSelector((state) => state.getAppLogoHeaderState.successGettingLogoAppHeader.vo);
  const isOnlyOneEmailOptionAvailable = emailPreferencesOptions && emailPreferencesOptions.length === 1;
  const onclickunsubscribestatus = useSelector((state) => state.unsubscribeEmail.successGettingOneClickUnsubscribe);
  const loadingState = useSelector((state) => state.loadingState);

  const isgetAppLogoHeaderLoading = loadingState.loading
    && (loadingState.loading.has('getAppLogoHeaderLoading'));
  const isSaveEmailPreferencesLoading = loadingState.loading
    && loadingState.loading.has('saveEmailPreferencesLoading');

  const errorResponse = (!isEmpty(errorGettingEmailPreferences) || !isEmpty(errorGettingLogoAppHeader) || !isEmpty(errorGettingUnsubscribeStatus)) && "An Error Occured";

  const [searchParams, setSearchParams] = useSearchParams();

  const k = searchParams.get("k")

  const onSave = () => {
    if (isOnlyOneEmailOptionAvailable) {
      const { optOutLevelCode } = emailPreferencesOptions[0];
      dispatch(triggerSaveEmailPreferencesRequest({ k, emailPreferenceOption: optOutLevelCode }));
    } else if (k && emailPreferenceOption) {
      dispatch(triggerSaveEmailPreferencesRequest({ k, emailPreferenceOption }));
    } else {
      console.log("Can't Save Preferences");
    }
  };

  const onClickOptions = (e) => {
    const { id } = e;
    setEmailPreferenceOption(id);
  };

  const isReadOnly = (vo && vo.toUpperCase() === 'Y') || false;
  return (
    onclickunsubscribestatus.message === 'SUBSCRIPTION_INACTIVE' ?
      <div>
        <NotificationBanner
          domID="api-notification"
          type='positive'
          text='You were previously unsubscribed.'
          onDismiss={() => dispatch(deleteNotification(k))}
          autoDismiss
          timer={1800000}
        />
      </div> :
      <div>
        {notifications.has(k) && (
          <NotificationBanner
            domID="api-notification"
            type={notifications.get(k).type}
            text={notifications.get(k).msg}
            onDismiss={() => dispatch(deleteNotification(k))}
            autoDismiss
            timer={1800000}
          />
        )}
        {(errorResponse && isEmpty(loadingState.loading)) && (
          <NotificationBanner
            domID="internal-error"
            type="negative"
            text={errorResponse}
            autoDismiss
            timer={1800000}
          />
        )}
        {!isEmpty(emailPreferencesOptions) && isArray(emailPreferencesOptions) &&
          <EmailPreferencesTemplate
            notifications={notifications}
            k={k}
            errorResponse={errorResponse}
            onClickOptions={onClickOptions}
            onSave={onSave}
            emailPreferencesOptions={emailPreferencesOptions}
            isgetAppLogoHeaderLoading={isgetAppLogoHeaderLoading}
            isSaveEmailPreferencesLoading={isSaveEmailPreferencesLoading}
            isValidEmailPreferenceOption={isValidEmailPreferenceOption}
            isOnlyOneEmailOptionAvailable={isOnlyOneEmailOptionAvailable}
            isReadOnly={isReadOnly}
          />}
      </div>
  );

};

export default EmailPreferences;
