import React from 'react';
import AppRoutes from './react/router/index';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
