import { put, call, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { getOneClickUnsubscribe } from './network';
import {
  getOneClickUnsubscribeRequestSuccess,
  getOneClickUnsubscribeRequestError
} from './actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_TRIGGER
} from './constants';
// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* getOneClickUnsubscribeGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\

  yield put(
    setLoadingState({
      id: 'getAppLogoHeaderLoading',
      isLoading: true,
    }),
  );

  try {
    const getOneClickUnsubscribeResponse = yield call(
      getOneClickUnsubscribe,
      action.payload,
    );

    // !isEmpty(getEmailPreferencesResponse) && isArray(getEmailPreferencesResponse)
    if (!isEmpty(getOneClickUnsubscribeResponse)) {
      yield put(getOneClickUnsubscribeRequestSuccess(getOneClickUnsubscribeResponse));
    }
    else {
      yield put(
        setLoadingState({
          id: 'getEmailPreferencesLoading',
          isLoading: false,
        }),
      );
    }
  }
  catch (thrownError) {
    yield put(getOneClickUnsubscribeRequestError(thrownError));
    yield put(
      setLoadingState({
        id: 'getEmailPreferencesLoading',
        isLoading: false,
      }),
    );
  }
}

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* subscribePreferencesListSaga() {
  yield takeLatest(
    GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_TRIGGER,
    getOneClickUnsubscribeGenerator,
  );
}


export default subscribePreferencesListSaga;
