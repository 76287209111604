import { SET_LOADING_STATE } from './constants';

export const setLoadingState = (payload) => ({
  type: SET_LOADING_STATE,
  payload,
});

export default {
  setLoadingState,
};
