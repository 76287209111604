import React from 'react';
import {
  BrowserRouter, Route, Routes
} from 'react-router-dom';

import GlobalStyles from '../shared/styles/globalStyles';
import AppHeader from '../containers/AppHeader';
import EmailPreferences from '../containers/EmailPreferences';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/emails/unsubscribe" element={<Home />} />
        <Route path="/" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {

  return (
    <div id="App" className="App">
      <GlobalStyles />
      <AppHeader />
      <EmailPreferences />
    </div>
  );

}

function Error() {

  return (
    <div id="App" className="App">
      <GlobalStyles />
      <center><h1>Page Not Found</h1></center>
    </div>
  );

}

export default AppRoutes;
