import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
} from './constants';

export const addNotification = (payload) => ({
  type: ADD_NOTIFICATION,
  payload,
});

export const deleteNotification = (id) => ({
  type: DELETE_NOTIFICATION,
  id,
});

export const deleteAllNotifications = () => ({
  type: DELETE_ALL_NOTIFICATIONS,
});

export default {
  addNotification,
  deleteNotification,
};
