import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    width: 100%;
  }

  *, * > * {
    box-sizing: border-box;
    font-family: 'Optum Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  @font-face {
    font-family: 'Optum Sans';
    src: url('/static/media/optum-sans-regular.woff2') format('woff2'),
    url('/static/media/optum-sans-regular.otf') format('opentype'),
    url('/static/media/optum-sans-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Optum Sans';
    src: url('/static/media/optum-sans-bold.woff2') format('woff2'),
    url('/static/media/optum-sans-bold.otf') format('opentype'),
    url('/static/media/optum-sans-bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

  #App {
    display: block;
    position: relative;
    bottom: 0;
    background-color: #FFF;
    height: 100%;
    width: 100%;
    overflow-x: auto;
  }

  p,
  label {
    line-height: 1.5em;
  }
`;

export default GlobalStyle;
