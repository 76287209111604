import Api from '../index';
import { API_BASE_PATH } from '../constants';

export const getOneClickUnsubscribe = async ({ k = '', l = '2' }) => {
  try {
    const api = new Api(k);
    const baseUrl = `${API_BASE_PATH}/getUnsubscribeStatus?l=${l}`;
    const getOneClickUnsubscribeResponse = await api.get(baseUrl);
    if (getOneClickUnsubscribeResponse.error) {
      throw getOneClickUnsubscribeResponse.error;
    }
    return getOneClickUnsubscribeResponse;
  } catch (error) {
    console.log('Error - getOneClickUnsubscribe', error);
    throw error;
  }
};

const exportedFunctions = {
  getOneClickUnsubscribe
};

export default exportedFunctions;
