import { put, call, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { getAppLogoHeader } from './network';
import {
  getAppLogoHeaderRequestSuccess,
  getAppLogoHeaderRequestError,
} from './actions';
import { setLoadingState } from '../../app/loadingState/actions';
import {
  GET_LOGO_APP_HEADER_REQUEST_TRIGGER,
} from './constants';

// This generator is the control flow manager that will step through the request
// lifecycle and dispatch actions to update the application at the end
function* getAppLogoHeaderGenerator(action) {
  // Before we make our network request we can dispatch actions to modify app
  // state, for example to show a spinner:
  // yield put(AppActions.startWaiting());\
  yield put(
    setLoadingState({
      id: 'getAppLogoHeaderLoading',
      isLoading: true,
    }),
  );


  try {
    const matchedLogo = yield call(
      getAppLogoHeader,
      action.payload,
    );

    // If we got what we expect, dispatch our success action
    if (!isEmpty(matchedLogo)) {
      yield put(getAppLogoHeaderRequestSuccess(matchedLogo));
    }
    yield put(
      setLoadingState({
        id: 'getAppLogoHeaderLoading',
        isLoading: false,
      }),
    );
  } catch (thrownError) {

    console.log('getAppLogoHeaderGenerator - error', thrownError);
    // console.log('getAppLogoHeaderPostGenerator', thrownError);
    // We got a 200 response that was valid JSON, but the expected data type
    // was not returned from the server so we pass a custom error out with our
    // failure action
    yield put(getAppLogoHeaderRequestError(thrownError));
    yield put(
      setLoadingState({
        id: 'getAppLogoHeaderLoading',
        isLoading: false,
      }),
    );
  }
}

function* getAppLogoHeaderSaga() {
  yield takeLatest(
    GET_LOGO_APP_HEADER_REQUEST_TRIGGER,
    getAppLogoHeaderGenerator,
  );
}

export default getAppLogoHeaderSaga;
