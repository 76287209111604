import Api from '../index';
import { API_BASE_PATH } from '../constants';

export const getAppLogoHeader = async ({ k = '' }) => {
  try {
    const api = new Api(k);
    const baseUrl = `${API_BASE_PATH}/getLogo`;
    const getAppLogoHeaderResponse = await api.get(baseUrl);
    if (getAppLogoHeaderResponse.error) {
      throw getAppLogoHeaderResponse.error;
    }
    return getAppLogoHeaderResponse;
  } catch (error) {
    console.log('Error - getAppLogoHeader', error);
    throw error;
  }
};

export default getAppLogoHeader;
