import {
  SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  SAVE_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  SAVE_EMAIL_PREFERENCES_REQUEST_ERROR,
  GET_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  GET_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  GET_EMAIL_PREFERENCES_REQUEST_ERROR,
} from './constants';

// This is the action that the UI will dispatch to trigger our request loop
export const triggerSaveEmailPreferencesRequest = (payload = {}) => ({
  type: SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  payload,
});

export const saveEmailPreferencesRequestSuccess = (payload = {}) => ({
  type: SAVE_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  payload,
  error: null,
});

export const saveEmailPreferencesRequestError = (error = {}) => ({
  type: SAVE_EMAIL_PREFERENCES_REQUEST_ERROR,
  error,
});

// This is the action that the UI will dispatch to trigger our request loop
export const triggerGetEmailPreferencesRequest = (payload = {}) => ({
  type: GET_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  payload,
});

export const getEmailPreferencesRequestSuccess = (payload = {}) => ({
  type: GET_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  payload,
  error: null,
});

export const getEmailPreferencesRequestError = (error = {}) => ({
  type: GET_EMAIL_PREFERENCES_REQUEST_ERROR,
  error,
});
