import Api from '../index';
import { API_BASE_PATH } from '../constants';

export const saveEmailPreferences = async ({ k = '', emailPreferenceOption = '' }) => {
  try {
    const api = new Api(k);
    const baseUrl = `${API_BASE_PATH}/saveEmailPreference?l=${emailPreferenceOption}`;
    const saveEmailPreferencesResponse = await api.put(baseUrl);
    if (saveEmailPreferencesResponse.error) {
      throw saveEmailPreferencesResponse.error;
    }
    return saveEmailPreferencesResponse;
  } catch (error) {
    console.log('Error - saveEmailPreferences', error);
    throw error;
  }
};

export const getEmailPreferences = async ({ k = '' }) => {
  try {
    const api = new Api(k);
    const baseUrl = `${API_BASE_PATH}/getEmailPreferences`;
    const getEmailPreferencesResponse = await api.get(baseUrl);
    if (getEmailPreferencesResponse.error) {
      throw getEmailPreferencesResponse.error;
    }
    return getEmailPreferencesResponse;
  } catch (error) {
    console.log('Error - getEmailPreferences', error);
    throw error;
  }
};

const exportedFunctions = {
  saveEmailPreferences,
  getEmailPreferences,
};

export default exportedFunctions;
