import 'core-js';
import 'regenerator-runtime/runtime';
import 'raf/polyfill';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import { UICLThemeProvider } from '@uicl/ui-core';
import App from './App';
import createStore from './redux';

const initialState = {};
const store = createStore(initialState);

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <UICLThemeProvider>
      <App />
    </UICLThemeProvider>
  </Provider>
);
