/**
 * API general class
 * This class constructor is used so we don't have to reuse the same code over again when it comes to fetching and headers
 * This is used calling the new API class such as const api = new Api();
 * const api = new Api(); and then api.get(), api.post(), api.put(), api.delete() with the required parameters passed in
 */

/**
  * Timeout function used to set a timeout for the API response
  * If the api takes longer than 10 seconds then we need to reject the promise being made
  * @param {Integer} time (miliseconds) time in which the timeout should trigger
  * @param {Promise} promise the promise function passed as the parameter

const apiTimeout = (time, promise) => new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(new Error('There is an error in server connection. Error Code: 280'));
  }, time);
  promise.then(resolve, reject);
});  */



class Api {
  constructor(k) {
    this.k = k;
  }

  getAuthorization() {
    return `Basic ${this.k}`;
  }

  async get(url) {
    try {
      const getRequest = await fetch(url, {
        method: 'GET',
        headers: {
          authorization: this.getAuthorization(),
          'Cache-Control': 'no-cache,no-store,max-age=0,must-revalidate',
          Pragma: 'no-cache',
          Expires: '-1',
        },
      });
      const getResponse = await getRequest.json();

      return getResponse;
    } catch (thrownError) {
      console.log(thrownError);
      // Throwing the object like this to get around the eslint rule of 'Expected an object to be thrown'
      throw Object.assign(
        new Error(thrownError.message),
        { errorId: thrownError.errorId, message: thrownError.message, code: thrownError.code },
      );
    }
  }


  async post(url, dataToPost) {
    try {
      const postRequest = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(dataToPost),
      });
      const postResponse = await postRequest.json();

      return postResponse;
    } catch (error) {
      throw error;
    }
  }

  async put(url) {

    try {
      const putRequest = await fetch(url, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          authorization: this.getAuthorization()
        },
      });
      const putResponse = await putRequest.json();

      return putResponse;

    } catch (thrownError) {
      console.log(thrownError);
      // Throwing the object like this to get around the eslint rule of 'Expected an object to be thrown'
      throw Object.assign(
        new Error(thrownError.message),
        { errorId: thrownError.errorId, message: thrownError.message, code: thrownError.code },
      );
    }

  }

  async delete(url, dataToDelete) {
    const deleteRequest = await fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: dataToDelete,
    });
    const deleteResponse = await deleteRequest.json();

    return deleteResponse;
  }
}

export default Api;
