// Import the action types we are going to respond to with this reducer
import {
  SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  SAVE_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  SAVE_EMAIL_PREFERENCES_REQUEST_ERROR,
  GET_EMAIL_PREFERENCES_REQUEST_TRIGGER,
  GET_EMAIL_PREFERENCES_REQUEST_SUCCESS,
  GET_EMAIL_PREFERENCES_REQUEST_ERROR,
} from './constants';

// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  isPostingEmailPreferences: false,
  successPostingEmailPreferences: '',
  errorPostingEmailPreferences: '',
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
const emailPreferencesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SAVE_EMAIL_PREFERENCES_REQUEST_TRIGGER:
      return {
        ...state,
      };

    case SAVE_EMAIL_PREFERENCES_REQUEST_SUCCESS:
      return {
        ...state,
        successPostingEmailPreferences: action.payload,
        errorPostingEmailPreferences: '',
      };
    case SAVE_EMAIL_PREFERENCES_REQUEST_ERROR:
      // If an error occurred you can set a fallback state here
      return {
        ...state,
        successPostingEmailPreferences: '',
        errorPostingEmailPreferences: action.error,
      };
    case GET_EMAIL_PREFERENCES_REQUEST_TRIGGER:
      return {
        ...state,
      };

    case GET_EMAIL_PREFERENCES_REQUEST_SUCCESS:
      return {
        ...state,
        successGettingEmailPreferences: action.payload,
        errorGettingEmailPreferences: '',
      };
    case GET_EMAIL_PREFERENCES_REQUEST_ERROR:
      // If an error occurred you can set a fallback state here
      return {
        ...state,
        successGettingEmailPreferences: '',
        errorGettingEmailPreferences: action.error,
      };
    default:
      // default case is to return the existing state
      return state;
  }
};

export default emailPreferencesReducer;
