import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AppHeaderComponent from '../../components/AppHeaderComponent';
import { triggerGetAppLogoHeaderRequest } from '../../../redux/api/logo/actions';
import { triggerGetEmailPreferencesRequest } from '../../../redux/api/EmailPreferences/actions';
import { triggergetOneClickUnsubscribe } from '../../../redux/api/UnsubscribeEmail/actions';
import { useSearchParams } from "react-router-dom";

const AppHeader = () => {

  const appLogoHeadersState = useSelector((state) => state.getAppLogoHeaderState);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const k = searchParams.get("k");

  useEffect(() => {
    dispatch(triggerGetAppLogoHeaderRequest({ k }));
    dispatch(triggerGetEmailPreferencesRequest({ k }));
    dispatch(triggergetOneClickUnsubscribe({ k }));

  }, []);

  const isSuccessResponse = !isEmpty(appLogoHeadersState.successGettingLogoAppHeader);

  return (
    <>
      {' '}
      {isSuccessResponse && <AppHeaderComponent idLogo={appLogoHeadersState.successGettingLogoAppHeader} />}
      {' '}
    </>
  );
};

export default AppHeader;
