import styled from 'styled-components';
import Button from '@uicl/ui-core/dist/Button';
import DetailpageTitle from '@uicl/ui-core/dist/DetailpageTitle';
import DetailpageDescription from '@uicl/ui-core/dist/DetailpageDescription';
import RadioButtonGroup from '@uicl/ui-core/dist/RadioGroup';
import TextStyles from '@uicl/ui-core/dist/TextStyles';

export const EmailPreferencesParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  min-width:650px;
  min-height:360px;
`;

export const EmailPreferencesContent = styled.div``;

export const EmailPreferencesNotification = styled.h1`
font-size: 20px;
text-align: center;
`;

export const PageHeaderContent = styled.div`
padding-top:20px;
h1 {
  font-size: 20pt;
  font-weight: 400;
  color: rgb(15, 15, 89) !important;
} 
span {
  ${TextStyles.large};
}
`;

export const PageDetailHeader = styled(DetailpageTitle)`
`;

export const PageSectionHeader = styled(DetailpageDescription)`
margin-top:5px;
color: rgb(76, 83, 103) !important;
`;

export const EmailPreferencesRadioGroup = styled.div`
margin: 10px 95px;
div.vertical {
  gap: 7px 16px;
}
div > span {
  min-width: 15px;
  min-height: 15px;
} 
label {
  color:rgb(15, 15, 89) !important;

}
`;

export const EmailPreferencesRadioButtonGroup = styled(RadioButtonGroup)`

`;


export const EmailPreferencesSubmitButtonDiv = styled.div`
  margin-top: 20px;
  display:flex;
  justify-content:center;

`;

export const EmailPreferencesSubmitButton = styled(Button)``;

export const LoadingWrapper = styled.div`
  margin-left:10px;
`;
