import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '@uicl/ui-core/dist/LoadingIndicator';
import {
  PageDetailHeader, PageSectionHeader, EmailPreferencesParent,
  EmailPreferencesRadioGroup,
  EmailPreferencesSubmitButton,
  EmailPreferencesRadioButtonGroup,
  EmailPreferencesContent,
  LoadingWrapper,
  EmailPreferencesSubmitButtonDiv,
  PageHeaderContent
} from './styles';

const EmailPreferencesComponent = ({
  onClickOptions,
  onSave,
  emailPreferencesOptions,
  isgetAppLogoHeaderLoading,
  isSaveEmailPreferencesLoading,
  isValidEmailPreferenceOption,
  isReadOnly
}) => {

  const generateOptionsFromProps = () => {
    return emailPreferencesOptions.map(record => {
      return {
        label: record.verbiage,
        id: record.optOutLevelCode.toString()
      };
    });
  }
  return (
    <EmailPreferencesParent>
      {isgetAppLogoHeaderLoading ? (
        <center>
          <LoadingIndicator
            domID="basic-details-form-loading-indicator"
            length="300px"
          />
        </center>
      )
        : (
          <div>
            <EmailPreferencesContent>
              <PageHeaderContent>
                <PageDetailHeader
                  dataTestId="email-prefheader-testid"
                  domID="email-prefheader-domid"
                  title="Email Preferences"
                />
                <PageSectionHeader
                  dataTestId="test-sectionBasic"
                  domID="test-id"
                  description="To make any changes to your email preferences, select below:"
                />
              </PageHeaderContent>
              <EmailPreferencesRadioGroup>
                <EmailPreferencesRadioButtonGroup
                  className="radio-button-group"
                  dataTestId="test-radio-button-group"
                  domID="test-radio-button-group"
                  size="large"
                  items={generateOptionsFromProps()}
                  label=""
                  name="someName"
                  onChange={(e) => onClickOptions(e)}
                  orientation="vertical"
                  disabled={isReadOnly}
                />
              </EmailPreferencesRadioGroup>
              <EmailPreferencesSubmitButtonDiv>
                <EmailPreferencesSubmitButton
                  buttonType="deEmphasized"
                  dataTestId="test-defaultButton"
                  domID="automation-id"
                  name="UPDATE"
                  onClick={() => onSave()}
                  size="small"
                  type="button"
                  disabled={isReadOnly || !isValidEmailPreferenceOption}
                />

                {isSaveEmailPreferencesLoading
                  && (
                    <LoadingWrapper>
                      <LoadingIndicator
                        domID="basic-details-form-loading-indicator"
                        length="30px"
                      />
                    </LoadingWrapper>
                  )}
              </EmailPreferencesSubmitButtonDiv>
            </EmailPreferencesContent>
          </div>
        )}
    </EmailPreferencesParent>
  );
}

EmailPreferencesComponent.propTypes = {
  onClickOptions: PropTypes.func,
  onSave: PropTypes.func,
  isgetAppLogoHeaderLoading: PropTypes.bool,
  isSaveEmailPreferencesLoading: PropTypes.bool,
  isValidEmailPreferenceOption: PropTypes.bool,
  isReadOnly: PropTypes.bool
};

export default EmailPreferencesComponent;
