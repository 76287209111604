// Import the action types we are going to respond to with this reducer
import {
  GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_TRIGGER,
  GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_SUCCESS,
  GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_ERROR
} from './constants';

// Initial state expresses the data type of this key in the store (in this case
// store.groupsList will be an Array). This object is exported separately to
// make unit testing easier
export const INITIAL_STATE = {
  successGettingOneClickUnsubscribe: '',
  errorGettingOneClickUnsubscribe: '',
};

// This is the actual "reducer". It is a function that will run every time an
// action is dispatched by the store, but only return updated state values if
// it matches the action's type in the switch statement.
const subscribePreferencesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_TRIGGER:
      return {
        ...state,
      };
    case GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_SUCCESS:
      return {
        ...state,
        successGettingOneClickUnsubscribe: action.payload,
        errorGettingOneClickUnsubscribe: ''
      };
    case GET_ONE_CLICK_UNSUBSCRIBE_REQUEST_ERROR:
      return {
        ...state,
        successGettingOneClickUnsubscribe: '',
        errorGettingOneClickUnsubscribe: action.error
      }
    default:
      // default case is to return the existing state
      return state;
  }
};

export default subscribePreferencesReducer;
